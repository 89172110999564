@import "./bulma-tables.min.css";
@import "./annotation.scss";

:root {
    --toastify-color-success: #00d1b2 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
}


.modal-background {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  .loader {
    height: 80px;
    width: 80px;
  }

  &.is-active {
    opacity: 1;
    z-index: 1;
  }
}

.category-menu{
  position: fixed;
  z-index: 100;
  margin-top: 8rem;

}

.images-hero{
  position: fixed;
  z-index: 29;
  left: 0;
  right: 0;
}


.images-grid{
  padding: 3rem;
  padding-top: 12rem;
}
.loader-wrapper{
  display: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 0;
  z-index: -1;
}

.loader-wrapper .loader {
  height: 80px;
  width: 80px;
}
.loader-wrapper.is-active {
  opacity: 1;
}

.category-input{
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.categories-container{
  height: calc(100vh - 6.5rem);
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.category-list {
  flex: 2;
  align-items: center;
}

.category-form{
  flex: 1;
  margin-left: 10rem;
  margin-right: 10rem;
}

#category-list{
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: 3.5rem;
}

.category-actions{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.7em;
  margin-top: 1rem;

}

#category-images{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.7em;
  margin-top: 1rem;
}

#category-images img{
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);

  cursor: pointer;
  transition: 0.15s;

}

#category-images img:hover{
  filter: brightness(0.8);
  transition: 0.15s;
}
#category-list:after {
  margin-top: 42vh;
}
@media screen and (max-width: 768px) {
  .category-menu{
    margin-top: 8rem;
    position: relative;
    z-index: 1;
  }
  .images-grid{
    padding: 1rem;
    padding-top: 2rem;
  }
}

#logo{
  margin-top: -10vh;
  margin-bottom: 5vh;
}

.interactive-grid-element{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  background-color: rgba(191, 221, 211, 0.3);
  margin: 0;
  border-radius: 15px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  transition: 0.15s ease all;
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  .loader{
    height: 120px;
    width: 120px;
  }
  svg{
    font-size: 5rem;
    color:rgba(0, 0, 0, 0.25);
  }
  &:hover{
    svg{
      color:rgba(0, 0, 0, 0.5);
      transition: 0.15s ease all;
    }
    cursor: pointer;
  }
}
