.wrapper {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 3.25em);
  overflow-y: hidden;

  .sidebar {
    width: 300px;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    position: relative;

    .controls {
      margin-top: 1rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .action-group:not(:first-child) {
        margin-top: 0.5rem;
      }

      .action-group {
        border: 1px solid #ccc;
        padding: 1rem;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .name {
          font-weight: bold;
          background-color: #f0f0f0;
          margin-top: -1.75em;
          padding: 0 0.5em;
        }

        .actions {
          display: flex;
          flex-direction: row;
          gap: 0rem;
          justify-content: space-between;
          align-items: center;

          button {
            width: 2.5em;
            height: 2.5em;
          }
        }

        .horizontal {
          flex-direction: column;
          justify-content: left;
          align-items: flex-start;
          gap: 0.3rem;

          .action-subgroup {
            display: flex;
            width: 100%;
            justify-content: center;
            flex-direction: row;
            align-items: center;
            gap: 1rem;

            .select {
              flex: 5;
              select{
                width: 100%;
              }
            }

            .color-circle {
              flex: 1;
              width: 10em !important;
              height: 1em;
              border-radius: 3em;
              border: 1px solid #ccc;
            }
          }
        }

        .history{
          overflow-y: scroll;
          height: 74vh;
        }
      }
    }

    #progress-selector {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 0 1rem;

      &::after {
        right: 10%;
      }
    }

    .image-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .save {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 0.5rem;

        :first-child {
          flex: 1;
        }

        :last-child {
          flex: 2;
        }
      }
    }
  }

  .main {
    flex: 1 1;
    display: flex;
    justify-content: center;
    overflow: hidden;
    background-image: /* tint image */ linear-gradient(
        to right,
        rgba(192, 192, 192, 0.75),
        rgba(192, 192, 192, 0.75)
      ),
      /* checkered effect */ linear-gradient(to right, rgb(87, 87, 87) 50%, white 50%),
      linear-gradient(to bottom, rgb(87, 87, 87) 50%, white 50%);
    background-blend-mode: normal, difference, normal;
    background-size: 2em 2em;
  }
}
